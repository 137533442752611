import React, { useEffect, useState } from 'react';
import './css/ImageSlider.css'; 
import { Link } from 'react-router-dom';
import Slider from 'react-slick'
import ImagesS from './ImagesS';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


const ImageSlider = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 920) {
        setSlidesToShow(1);
      } else if (width <= 1400) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
    <div className='bodyS'>
    <div className="contentS">
      <div className="containerS">
        <Slider {...settings}>
          {ImagesS.map((item) => (
            <div key={item.id}>
              <Link to="/products" style={{textDecoration: 'none'}}>
              <img src={item.src} alt={item.alt} className="imgS"></img></Link>
              <div className='overlayS'>{item.title}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
    </div>
  </>
  );
};

export default ImageSlider;
