
import bw80 from './pics/saturatedPaper.png'
import p80 from './pics/grSheets.png'
import p125S from './pics/krem.png'

const ImagesS = [

    {
        id: 1,
        src: bw80,
        alt: "Image 1",
        title: 'Teximax 80BW',
        
    },{
        id: 2,
        src: p80,
        alt: "Image 1",
        title: 'Teximax 80P',
    }, {
        id: 3,
        src: bw80,
        alt: "Image 1",
        title: 'Teximax 110BW'
    }, {
        id: 4,
        src: bw80,
        alt: "Image 1",
        title: 'Teximax 120BW'
    }, {
        id: 5,
        src: p125S,
        alt: "Image 1",
        title: 'Teximax 125S'
    }, ];

    export default ImagesS;


