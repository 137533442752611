import React from 'react';
import unroll from './pics/divHomeo.png'
import { Link } from 'react-router-dom';
import "./css/Home.css"
import qvl from "./icons/quality.png"
import env from "./icons/envFriendly.png"
import clean from "./icons/clean.png"
import res from "./icons/resizable.png"
import "./css/test.css"
import ImageSlider from './ImageSlider'


const Home = ({ language,isMobile }) => {

return(
  <>
<div className='gradHome' style={{marginBottom: '15px',position: 'relative',display: 'inline-block', boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",}}>
  <img src={unroll} alt='unroll' style={{width: '100%',height: 'auto',display:"block"}}></img>
  {language === 'en' ? 
 <div className='overlay' style={{fontSize: '3.3vw',color: '#bdbcd5',textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'}}>
  
  <b>Producer of high-quality<br/> thermal transfer papers </b>
 </div> :
  <div className='overlay' style={{fontSize: '3.3vw',color: '#bdbcd5',textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'}}>

  <b>Proizvodjač visoko kvalitetnih <br/>termo transfer papira</b>
 </div>
}
  </div>


  <div className='container' style={{ marginTop: '10px'}}>
  {!isMobile ? ( 
    
    language === 'en' ? ( 
      <>
  <p
  style={{
    fontFamily: 'sans-serif',
    lineHeight: '1.5',
    paddingLeft: '250px',
    paddingRight: '250px',
    fontSize: 'x-large'
  }}>

 <p><b><h2 style={{textAlign:'center'}}>Welcome to Powermax d.o.o.</h2></b></p>
<p style={{paddingLeft: '30px',
       
       fontFamily: "Roboto Regular,Helvetica Neue,Helvetica,Arial,sans-serif",
       fontsize: '26px',
       lineheight: '31px',
       letterspacing: '1.38px',
       fontweight: 'normal',
       
}}>
We manufacture high-quality thermal transfer papers for replicating screen printing on textiles.
Powermax d.o.o. is a family business founded in 2007 in Bačka Palanka, Republic of Serbia. 
Our commitment to quality and innovation allows us to offer you products that meet the highest standards.<br/><br/>
</p>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Our Mission</b></p>
<p style={{paddingLeft: '30px'}}>
The mission of Powermax d.o.o. is to provide the highest quality transfer papers to help our clients to achieve perfect results in their work.
</p><br/>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Our Products</b></p>

<p style={{paddingLeft: '30px'}}>
Powermax d.o.o. transfer papers are designed to provide exceptional accuracy, color fastness, 
have excellent dimensional stability and are compatible with all plastisol inks and all types of adhesives. Suitable for multi-color printing.
</p></p><br/>
{/* <div style={{display: 'flex', justifyContent: 'center',alignItems: 'center'}}>
<div class="image-container">
        <img src={bw80} alt="Image 1"></img>
        <div class="overlayZ">TexiMax 80BW</div>
    </div>
    <div class="image-container">
    <img src={p80} alt="Image 1"></img>
        <div class="overlayZ">TexiMax 80P</div>
    </div>
    <div class="image-container">
    <img src={p125S} alt="Image 1" style={{objectFit: 'contain'}}></img>
        <div class="overlayZ">TexiMax 125S</div>
    </div>
    </div> */}
<ImageSlider/><br/>
<p style={{ fontSize: 'x-large',paddingLeft: '30px',width: '100%',textAlign:'center',marginBottom: '100px'}}>
See more about the Products&nbsp; <Link to="/products" style={{textDecoration: 'none'}}> here</Link>.</p>

<br /><br />
{/* Why US */}

  <br/>
<div className='wcp'><h2><b>Why choose Powermax d.o.o.?</b></h2></div><br/>
  <div className='why'>
<span className='inlB'><img className='whyIcons' src={qvl} alt="quality"></img>
<p><b><h2>Quality and reliability</h2></b></p>
<p className='tristaPedeset'>  Our products are the result of many years of experience and careful selection of materials, 
  which guarantees constant quality, and durability of prints.</p>
</span>

<span className='inlB'><img className='whyIcons' src={clean} alt="quality"></img>
<p><b><h2>High precision</h2></b></p>
<p className='tristaPedeset'>Enables precise and clear prints, without spilling (bleeding) or loss of details.</p></span>

<span className='inlB'><img className='whyIcons' src={res} alt="quality"></img>
<p><b><h2>Customised solutions</h2></b></p>
<p className='tristaPedeset'>We offer solutions that will best suit your requirements.</p></span>

<span className='inlB'><img className='whyIcons' src={env} alt="quality"></img>
<p><b><h2>Environmentally friendly</h2></b></p>
<p className='tristaPedeset'>Made from materials that are safe for the environment and do not contain harmful chemicals.</p></span>

</div><br/><br/>

{/* Contact */}
<div style={{width: 'inherit',marginTop: '100px',fontSize:'x-large'}}>
<p style={{paddingLeft:'15px'}}><b><h1>
<Link to="/contact" className='hovPurp'>Contact us</Link></h1></b></p>
<p style={{paddingLeft: '30px'}}>
We are ready to answer all of your questions and requests.<br/>
</p>
</div>
<br/>

  </>
) : (
<>
  <p
  style={{
    fontFamily: 'sans-serif',
    lineHeight: '1.5',
    paddingLeft: '250px',
    paddingRight: '250px',
    fontSize: 'x-large'
  }}>

 <p><b><h2 style={{textAlign: "center"}}>Dobrodošli u Powermax d.o.o.</h2></b></p>
<p style={{paddingLeft: '30px',
        //  fontFamily: "Roboto Regular,Helvetica Neue,Helvetica,Arial,sans-serif",
         fontsize: '26px',
         lineheight: '31px',
         letterspacing: '1.38px',
         fontweight: 'normal',
         
}}>
Vaš pouzdan partner, proizvođač visoko kvalitetnih termo transfer papira za izradu preslikača za sito štampu na tekstilu.<br/>
Powermax d.o.o. je porodična firma osnovana 2007. godine u Bačkoj Palanci u Republici Srbiji.
 Naša posvećenost kvalitetu i inovacijama omogućava da vam ponudimo proizvode koji zadovoljavaju najviše standarde.<br/>
</p><br/>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Naša misija</b></p>
<p style={{paddingLeft: '30px'}}>
Misija kompanije Powermax d.o.o. je da obezbedi najkvalitetniji transfer papir koji će pomoći našim klijentima da postignu savršene rezultate u radu. 
</p><br/>

<p style={{paddingLeft:'15px',textAlign: 'center'}}><b>Naši proizvodi</b></p>

<p style={{paddingLeft: '30px'}}>
Powermax d.o.o. transfer papiri su dizajnirani da pruže izuzetnu preciznost, postojanost otisaka, imaju odličnu dimenzionalnu stabilnost i kompatiblini su
sa svim plastizol bojama i svim vrstama lepkova.
Pogodni su za visebojnu stampu.
</p></p>
<ImageSlider/>
<p style={{ fontSize: 'x-large',paddingLeft: '30px',width: '100%',textAlign:'center',marginBottom: '100px'}}>
Vise o Proizvodima pogledajte<Link to="/products" style={{textDecoration: 'none'}}> ovde</Link>.</p><br/>


<div className='wcp'><h2><b>Zašto odabrati Powermax d.o.o.?</b></h2></div>
  <div className='why'>
<span className='inlB'><img className='whyIcons' src={qvl} alt="quality"></img>
<p><b><h2>Kvalitet i pouzdanost</h2></b></p>
<p className='tristaPedeset'>  Proizvodi su rezultat dugogodišnjeg iskustva i pažljivog odabira materijala, što garantuje konstantan kvalitet,
dugotrajnost i postojanost otisaka.</p>
</span>

<span className='inlB'><img className='whyIcons' src={clean} alt="quality"></img>
<p><b><h2>Visoka preciznost</h2></b></p>
<p className='tristaPedeset'>Omogućava precizne i jasne otiske, bez razlivanja ili gubitka detalja.</p></span>

<span className='inlB'><img className='whyIcons' src={res} alt="quality"></img>
<p><b><h2>Prilagođena rešenja</h2></b></p>
<p className='tristaPedeset'>Nudimo rešenja koja će najbolje odgovarati vašim zahtevima.</p></span>

<span className='inlB'><img className='whyIcons' src={env} alt="quality"></img>
<p><b><h2> Ekološki prihvatljiv</h2></b></p>
<p className='tristaPedeset'>Izrađeni od materijala koji su sigurni za okolinu i ne sadrže štetne hemikalije.</p></span>

</div><br/><br/>

<div style={{width: 'inherit',marginTop: '100px',fontSize:'x-large'}}>
<p style={{paddingLeft:'15px'}}><b><h1>
<Link to="/contact" style={{textDecoration: 'none'}}>Kontaktirajte nas</Link></h1></b></p>
<p style={{paddingLeft: '30px'}}>
Spremni smo da odgovorimo na sva vaša pitanja i zahteve.<br/>
</p></div>
    <br /><br />

  </>
   )

      ):(





          //Mobile
  language === 'en' ? ( 
<>
    <p
    style={{
      fontFamily: "Roboto Regular,Helvetica Neue,Helvetica,Arial,sans-serif",
      lineHeight: '1.5',


    }}>
  
  <p><b><h2 style={{textAlign:'center'}}>Welcome to Powermax d.o.o.</h2></b></p>
<p style={{paddingLeft: '30px',}}>
We manufacture high-quality thermal transfer papers for replicating screen printing on textiles.
Powermax d.o.o. is a family business founded in 2007 in Bačka Palanka, Republic of Serbia. 
Our commitment to quality and innovation allows us to offer you products that meet the highest standards.<br/>
</p>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Our Mission</b></p>
<p style={{paddingLeft: '30px'}}>
The mission of Powermax d.o.o. is to provide the highest quality transfer paper to help our clients achieve perfect results in their work.
</p>

</p>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Our Products</b></p>

<p style={{paddingLeft: '30px'}}>
Powermax d.o.o. transfer papers are designed to provide exceptional accuracy, color fastness, 
have excellent dimensional stability and are compatible with all plastisol paints and all types of adhesives. Suitable for multi-color printing.
</p>
<ImageSlider/>

{/* Why us Mobile */}
    <div className='wcp'><h4><b>Why choose Powermax d.o.o.?</b></h4></div>
  <div className='why'>
<span className='inlB'><img className='whyIcons' src={qvl} alt="quality"></img>
<p><b><h2>Quality and reliability</h2></b></p>
<p className='tristaPedeset'>  Our products are the result of many years of experience and careful selection of materials, 
  which guarantees constant quality, and durability of prints.</p>
</span>

<span className='inlB'><img className='whyIcons' src={clean} alt="quality"></img>
<p><b><h2>High precision</h2></b></p>
<p className='tristaPedeset'>Enables precise and clear prints, without spilling (bleeding) or loss of details.</p></span><br/>

<span className='inlB'><img className='whyIcons' src={res} alt="quality"></img>
<p><b><h2>Customised solutions</h2></b></p>
<p className='tristaPedeset'>We offer solutions that will best suit your requirements.</p></span>

<span className='inlB'><img className='whyIcons' src={env} alt="quality"></img>
<p><b><h2>Environmentally friendly</h2></b></p>
<p className='tristaPedeset'>Made from materials that are safe for the environment and do not contain harmful chemicals.</p></span>

</div><br/><br/>
<div style={{marginTop: '30px',width: '100%'}}>
<p style={{paddingLeft:'15px',}}><b>
<Link to="/contact" style={{textDecoration: 'none'}}><h1>Contact us</h1></Link></b></p>
<p style={{paddingLeft: '30px'}}>
We are ready to answer all of your questions and requests.<br/>
</p>
</div>
  </>

  ):(
    <>
        <p
    style={{
      fontFamily: 'sans-serif',
      lineHeight: '1.5',

    }}>
  
  <p><b><h2 style={{textAlign:'center'}}>Dobrodošli u Powermax d.o.o.</h2></b></p>
<p style={{paddingLeft: '30px'}}>
Vaš pouzdan partner, proizvođač visoko kvalitetnog termo transfer papira za izradu preslikača za sito štampu na tekstilu.<br/>
Powermax d.o.o. je porodična firma osnovana 2007. godine u Bačkoj Palanci u Republici Srbiji.
 Naša posvećenost kvalitetu i inovacijama omogućava da vam ponudimo proizvode koji zadovoljavaju najviše standarde.<br/>
</p>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Naša misija</b></p>
<p style={{paddingLeft: '30px'}}>
Misija kompanije Powermax d.o.o. je da obezbedi najkvalitetniji transfer papir koji će pomoći našim klijentima da postignu savršene rezultate u radu. 
</p>

<p style={{paddingLeft:'15px',textAlign:'center'}}><b>Naši proizvodi</b></p>

<p style={{paddingLeft: '30px'}}>
Powermax d.o.o. transfer papiri su dizajnirani da pruže izuzetnu preciznost, postojanost boja, imaju odličnu dimenzionalnu stabilnost i kompatiblini su
sa svim plastisol bojama i svim vrstama lepkova.
Pogodni za visebojnu stampu.
</p></p>
<ImageSlider/>
<p style={{paddingLeft: '30px'}}>
Vise o Proizvodima pogledajte<Link to="/products" style={{textDecoration: 'none'}}> ovde</Link>.</p><br/>


<div className='wcp'><h2><b>Zašto odabrati Powermax d.o.o.?</b></h2></div>
  <div className='why'>
<span className='inlB'><img className='whyIcons' src={qvl} alt="quality"></img>
<p><b><h2>Kvalitet i pouzdanost</h2></b></p>
<p className='tristaPedeset'>  Proizvodi su rezultat dugogodišnjeg iskustva i pažljivog odabira materijala, što garantuje konstantan kvalitet,
dugotrajnost i postojanost otisaka.</p>
</span>

<span className='inlB'><img className='whyIcons' src={clean} alt="quality"></img>
<p><b><h2>Visoka preciznost</h2></b></p>
<p className='tristaPedeset'>Omogućava precizne i jasne otiske, bez razlivanja ili gubitka detalja.</p></span><br/>

<span className='inlB'><img className='whyIcons' src={res} alt="quality"></img>
<p><b><h2>Prilagođena rešenja</h2></b></p>
<p className='tristaPedeset'>Nudimo rešenja koja će najbolje odgovarati vašim zahtevima.</p></span>

<span className='inlB'><img className='whyIcons' src={env} alt="quality"></img>
<p><b><h2> Ekološki prihvatljiv</h2></b></p>
<p className='tristaPedeset'>Izrađeni od materijala koji su sigurni za okolinu i ne sadrže štetne hemikalije.</p></span>

</div><br/><br/>
<div style={{marginTop: '30px',width: '100%'}}>
<p style={{paddingLeft:'15px',}}><b>
<Link to="/contact" style={{textDecoration: 'none'}}><h1>Kontaktirajte nas</h1></Link></b></p>
<p style={{paddingLeft: '30px'}}>
Spremni smo da odgovorimo na sva vaša pitanja i zahteve.<br/>
</p>
    <br /><br />
    </div>
    </>
  )
      )}
</div>

      </>
);
}

export default Home;