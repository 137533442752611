import React, { useState } from 'react';

function ContactFormM({ language }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = { name: '', email: '', message: '' };
    let isValid = true;

    // Check if name is empty
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    // Check if email is valid
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is not valid';
      isValid = false;
    }

    // Check if message is empty
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Construct the mailto URL
      const recipientEmail = 'office.powermax@gmail.com'; // Replace with the recipient's email
      const subject = encodeURIComponent(`Message from ${formData.name}`);
      const body = encodeURIComponent(
        `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
      );

      const mailtoURL = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;

      // Redirect to the mailto URL
      window.location.href = mailtoURL;
    }
  };

  return (
    <div style={{display:'flex',justifyContent: 'center',alignItems:'center',marginTop:'50px'}}>
      <form onSubmit={handleSubmit} style={{border: '3px solid #23559b',padding: '20px',borderRadius:'15px',margin:'20px'}}>
      {language === 'en' ? (
        <h2 style={{textAlign: 'center',color: '#23559b'}}>Direct Contact</h2>) : (
            <h2 style={{textAlign: 'center',color: '#23559b'}}>Direktan Kontakt</h2>
        )}
        <label htmlFor="name">{language === 'en' ? 'Name' : 'Ime'}:</label><br/>
        <input 
          type="text" 
          id="name" 
          name="name" 
          value={formData.name}
          onChange={handleChange}
          placeholder={language === 'en' ? 'Name' : 'Ime'} 
        />
        {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
        <br/>
        <label htmlFor="email">E-mail:</label><br/>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email}
          onChange={handleChange}
          placeholder={language === 'en' ? "Your mail" : 'Vaš mail'} 
        />
        {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
        <br/>
        <label htmlFor="message">{language === 'en' ? 'Message' : 'Poruka'}:</label><br/>
        <textarea 
          id="message" 
          name="message" 
          rows="4" 
          cols="40"
          value={formData.message}
          onChange={handleChange}
          placeholder={language === 'en' ? "Your message" : 'Vaš upit'}
        ></textarea>
        {errors.message && <div style={{ color: 'red' }}>{errors.message}</div>}
        <br/>
        <button className='buttonSubm' type="submit">{language === 'en' ? 'Send' : 'Pošalji'}</button>
      </form>
    </div>
  );
}

export default ContactFormM;