import React from "react";
import { useState,useEffect } from "react";
import pp from './pics/cpm.jpg'
import peel from './pics/peelS.png'
import bw80 from './pics/saturatedPaper.png'
import p80 from './pics/grSheets.png'
import p125S from './pics/krem.png'
import Pdf80bw from "./pdf/Pdf80bw";
import './css/Product.css'
import './css/Modal.css'
import Pdf110 from './pdf/Pdf110';
import Pdf80p from "./pdf/Pdf80p";
import Pdf125s from "./pdf/Pdf125s";
import Pdf120bw from "./pdf/Pdf120bw";


const Products = ({language, toggleLanguage}) => {
  console.log("Current language in Products:", language);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1068); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>

    <div style={{position: 'relative',display: 'inline-block', boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"}}>
        <div className="okvirProduct">
    <img src={peel} alt='peel' style={{width: '100%',height: 'auto',display:"block",}}></img>
    </div>
    </div>
    <div style={{margin:'10px 1px'}}><h1 style={{textAlign: 'center','fontSize': isMobile ? '40px' : '50px', color: '#23559b'}}>{language === 'en' ? 'Products' : 'Proizvodi'}</h1></div>

  
<br />
{/* Papir meni skrol */}
{!isMobile ? (
  <>
<div className="topnav stickyElement">
<a href="#plavi"><button>80BW</button></a>
<a href="#zeleni"><button>80P</button></a>
<a href="#debeli"><button>110BW</button></a>
<a href="#novi"><button>120BW</button></a>
<a href="#krem"><button>125S</button></a>
</div>



{/* plaviPC */}
{language === 'en' ? (
<div className="okvir" id="plavi" >
<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80BW</h3><div style={{paddingRight: '90px',paddingLeft: '90px'}}>
High-quality transfer paper for Screen printing on textile with plastisol inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 80g./sm</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light blue and white</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered or paste adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>

<i>Recommended processing time: Within one year from the date of production.</i><br/><br/>

<b>Transfer process:</b><br/>
Application temperatures: 130° to 205°C<br/>
Dwell time: 10–15 seconds<br/>
Pressure: Medium (35 to 45 psi)<br/><br/>


<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></div>
<Pdf80bw/>
</div>
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={bw80} alt="bw80" ></img></div>
 </div>

):(
<div className="okvir" id="plavi" >
<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80BW</h3><div style={{paddingRight: '90px',paddingLeft: '90px'}}>
Visoko kvalitetan transfer papir za sito štampu za izradu preslikača na tekstilu sa plastizol bojama.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 80g./m²</li>
        <li>dizajniran za Cold peel trasfere </li>
        <li>boja papira: svetlo plavo-belo</li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste (poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT</b><br/><br/>

<i>Preporučeno vreme upotrebe: Godinu dana od datuma proizvodnje</i><br/><br/>

<b>Proces trasfera:</b><br/>
Temperatura prese: od 130° to 205°C<br/>
Vreme transfera 10–15 sekundi<br/>
Pritisak: srednji (35 do 45 psi)<br/><br/>


<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>* Željene dimenzije su dostupne na zahtev kupca</i></div>
<Pdf80bw/>
</div>
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={bw80} alt="bw80" ></img></div>
 </div>

)}



{/* zeleniPC */}
{language === 'en' ? (
 <div className="okvir" id="zeleni">

 <div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={p80} alt="p80" ></img></div>

<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80P</h3><p style={{paddingRight: '90px',paddingLeft: '90px'}}>
 High-quality transfer paper for Screen printing on textile with plastisol inks
 with the excellent dimensional stability. Suitable for multi-color transfers.<br/><br />
 <b>Properties:</b><br />
<ul>
        <li>Weight 80g./m²</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light green</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered or paste adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>

<i>Recommended processing time: Within one year from the date of production.</i><br/><br/>

<b>Transfer process:</b><br/>
Application temperatures: 130° to 205°C<br/>
Dwell time: 10–15 seconds<br/>
Pressure: Medium (35 to 45 psi)<br/><br/>

<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf80p/>
</div>

 </div>
 ):(
        <div className="okvir" id="zeleni" >
         <div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={p80} alt="p80" ></img></div>
        <div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80P</h3><div style={{paddingRight: '90px',paddingLeft: '90px'}}>
        Visoko kvalitetan transfer papir za sito štampu za izradu preslikača na tekstilu sa plastizol bojama. Izuzetna dimenzionalna stabilnost.
        Pogodan za višebojne trasfere.<br/><br />
        <b>Svojstva:</b><br />
        <ul>
                <li>težina 80g./m²</li>
                <li>dizajniran za Cold peel trasfere </li>
                <li>boja papira: svetlo zelena</li>
        </ul>
        <b>Sito štampanje:</b><br/><br/>
        Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste(poliester,poliamid,poliuretan).<br/>
        <br/>
        Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT.</b><br/><br/>
        
        <i>Preporučeno vreme upotrebe: Godinu dana od datuma proizvodnje</i><br/><br/>
        
        <b>Proces trasfera:</b><br/>
        Temperatura primene: 130° to 205°C<br/>
        Vreme transfera: 10–15 sekundi<br/>
        Pritisak: srednji (35 do 45 psi)<br/><br/>
        
        
        <b>Podrazumevano pakovanje:</b><br /><br />
        &nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
        &nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
        <i>* Željene dimenzije su dostupne na zahtev kupca</i></div>
        <Pdf80p/>
        </div>

         </div>
        
        )}
        




{/* debeliPC */}
{language === 'en' ? (
<div className="okvir" id="debeli">
<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 110BW</h3><p style={{paddingRight: '90px',paddingLeft: '90px'}}>
 High-quality transfer paper for Screen printing on textile with plastisol inks. Excellent dimensional stability.
 Suitable for multi-color transfers.<br/><br />
 <b>Properties:</b><br />
<ul>
        <li>Weight 105g./sm</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light blue and White</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered or paste adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>

<i>Recommended processing time: Within one year from the date of production.</i><br/><br/>

<b>Transfer process:</b><br/>
Application temperatures: 130° to 205°C<br/>
Dwell time: 10–15 seconds<br/>
Pressure: Medium (35 to 45 psi)<br/><br/>

<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf110/>
</div>
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={bw80} alt="bw80" ></img></div>

 </div>

) : (
<div className="okvir" id="debeli" >
<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 110BW</h3><div style={{paddingRight: '90px',paddingLeft: '90px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu sa plastizol bojama. Pogodan za višebojne transfere. Odlična dimenzionalna stabilnost.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 105g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
        <li>boja papira: svetlo plavo-belo</li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste(poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT</b><br/><br/>

<i>Preporučeno vreme upotrebe: Godinu dana od datuma proizvodnje</i><br/><br/>

<b>Proces trasfera:</b><br/>
Temperatura primene: 130° to 205°C<br/>
Vreme zadržavanja: 10–15 sekundi<br/>
Pritisak: srednji (35 to 45 psi)<br/><br/>


<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>* Željene dimenzije su dostupne na zahtev kupca</i></div>
<Pdf110/>
</div>
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={bw80} alt="bw80" ></img></div>
 </div>

)}



{/* 120 */}

{language === 'en' ? (

 <div className="okvir" id="novi">

 <div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={bw80} alt="bw120" ></img></div>

<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 120BW</h3><p style={{paddingRight: '90px',paddingLeft: '90px'}}>
High-quality transfer paper for Screen printing on textile with plastisol inks. Excellent dimensional stability.
Suitable for multi-color transfers.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 120g./sm</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light blue and White</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered or paste adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>
<i>Recommended processing time: Within one year from the date of production.</i><br/><br/>

<b>Transfer process:</b><br/>
Application temperatures: 130° to 205°C<br/>
Dwell time: 10–15 seconds<br/>
Pressure: Medium (35 to 45 psi)<br/><br/>

<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf120bw/>
</div>

 </div>

) : (

<div className="okvir" id="novi" >
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={bw80} alt="bw80" ></img></div>
<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 120BW</h3><div style={{paddingRight: '90px',paddingLeft: '90px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu sa plastizol bojama. Pogodan za višebojne transfere. Odlična dimenzionalna stabilnost.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 120g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
        <li>boja papira: svetlo plavo-belo </li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste(poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT</b><br/><br/>

<i>Preporučeno vreme upotrebe: Godinu dana od datuma proizvodnje</i><br/><br/>

<b>Proces trasfera:</b><br/>
Temperatura primene: 130° to 205°C<br/>
Vreme zadržavanja: 10–15 sekundi<br/>
Pritisak: srednji (35 do 45 psi)<br/><br/>


<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>* Željene dimenzije su dostupne na zahtev kupca</i></div>
<Pdf120bw/>
</div>

 </div>

)}


 {/* kremPC */}

 {language === 'en' ? (
 <div className="okvir" id="krem">

<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 125S</h3><p style={{paddingRight: '20px',paddingLeft: '90px'}}>
High quality transfer paper for Screen printing on textile with water based inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 125g./sm</li>
        <li>Designed for cold peel transfers</li>

</ul>
<b>Screen printing:</b><br/><br/>
Printing side is the side with thicker silicone coating and 
is much easier for peeling.<br/>
<br/>
Final appearance after transferring on textile - <b>GLOSSY</b><br/><br/>

<b>Transfer process:</b><br/>
Application temperatures: 130° to 205°C<br/>
Dwell time: 10–15 seconds<br/>
Pressure: Medium (35 to 45 psi)<br/><br/>

<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;200 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;400 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf125s/>
</div>
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={p125S} alt="p125S" ></img></div>
 </div>

 ) : (

<div className="okvir" id="krem" >
<div className="box"><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 125S</h3><div style={{paddingRight: '20px',paddingLeft: '90px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu sa bojama na vodenoj bazi.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 125g./sm</li>
        <li>dizajniran za cold peel trasfere </li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Štampanje na strani sa debljim silikonskim premazom, odnosno strana koja ima manji otpor prilikom ljuštenja.<br/>
<br/>
<div style={{fontSize: '25px'}}>Finalni izgled otiska nakon prenosenja na tekstil - <b style={{fontSize: '25px'}}>GLOSSY</b></div><br/><br/>

<i>Preporučeno vreme upotrebe: Godinu dana od datuma proizvodnje</i><br/><br/>

<b>Proces trasfera:</b><br/>
Temperatura primene: 130° to 205°C<br/>
Vreme zadržavanja: 10–15 sekundi<br/>
Pritisak: srednji (35 do 45 psi)<br/><br/>

<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;200 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;400 tabaka 50 x 70 cm<br /><br />
<i>* Željene dimenzije su dostupne na zahtev kupca</i></div>
<Pdf125s/>
</div>
<div className="box" style={{display: 'flex', justifyContent: 'center'}}><img className="bImg" src={p125S} alt="p125S" ></img></div>
 </div>


 )}

 </>

 
):(
  <>
  {/*  mobile */}
<div className="topnav stickyElementM" style={{width: '90%',marginBottom: '50px'}}>
<a href="#plavi"><button style={{textAlign:'center',fontSize:'10px',width:'18%',margin: '0 3px',fontWeight:'bold'}}>80BW</button></a>
<a href="#zeleni"><button style={{textAlign:'center',fontSize:'10px',width:'18%',margin: '0 3px',fontWeight:'bold'}}>80P</button></a>
<a href="#debeli"><button style={{textAlign:'center',fontSize:'10px',width:'18%',margin: '0 3px',fontWeight:'bold'}}>110BW</button></a>
<a href="#novi"><button style={{textAlign:'center',fontSize:'10px',width:'18%',margin: '0 3px',fontWeight:'bold'}}>120BW</button></a>
<a href="#krem"><button style={{textAlign:'center',fontSize:'10px',width:'18%',margin: '0 3px',fontWeight:'bold'}}>125S</button></a>
</div>

{/* plaviM */}

{language === 'en' ? (
<div id="plavi" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80BW</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
 High-quality transfer paper for Screen printing on textile with plastisol inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 80g./sm</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light blue and white</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered or paste adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>
<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
{/* <a href={pdf80} download={"80BW"}>download</a> */}
<Pdf80bw/>

</div>
<div className="okvirSlikeM"><img src={bw80} alt="bw80" style={{width: '60%',objectFit:'contain'}} ></img></div>
 </div>

) : (
        <div id="plavi" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80BW</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu sa plastizol bojama.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 80g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
        <li>boja papira: svetlo plavo-belo</li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste (poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT.</b><br/><br/>
<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>*Željene dimenzije su dostupne na zahtev kupca </i></p>
<Pdf80bw/>

</div>
<div className="okvirSlikeM"><img src={bw80} alt="bw80" style={{width: '60%',objectFit:'contain'}} ></img></div>
 </div>
)}


 {/* zeleniM */}

 {language === 'en' ? (
 <div id="zeleni" className="okvirM" >
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80P</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
 High-quality transfer paper for Screen printing on textile with plastisol inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 80g./sm</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light green</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>
<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf80p/>

</div>
<div className="okvirSlikeM"><img src={p80} alt="p80" style={{width: '60%'}} ></img></div>
 </div>

 ) : (
        <div id="zeleni" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 80P</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu za plastizol boje.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 80g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
        <li>boja papira: svetlo zelena</li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste (poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT.</b><br/><br/>
<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>*Željene dimenzije su dostupne na zahtev kupca </i></p>
<Pdf80p/>

</div>
<div className="okvirSlikeM"><img src={p80} alt="p80" style={{width: '60%',objectFit:'contain'}} ></img></div>
 </div>
 )}


{/* debeliM */}
{language === 'en' ? (
 <div id="debeli" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 110BW</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
 High-quality transfer paper for Screen printing on textile with plastisol inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 110g./sm</li>
        <li>Designed for cold peel transfers</li>
        <li>Paper color: Light blue and white</li>
</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered adhesives (polyester, polyamide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE.</b><br/><br/>
<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf110/>

</div>
<div className="okvirSlikeM"><img src={bw80} alt="bw110" style={{width: '60%'}} ></img></div>
 </div>

) : (
        <div id="debeli" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 110BW</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu za plastizol boje.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 110g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
        <li>boja papira: svetlo plavo-belo</li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste (poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT.</b><br/><br/>
<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>*Željene dimenzije su dostupne na zahtev kupca </i></p>
<Pdf110/>

</div>
<div className="okvirSlikeM"><img src={bw80} alt="bw80" style={{width: '60%',objectFit:'contain'}} ></img></div>
 </div>
)}


{/* 120 */}
{language === 'en' ? (
 <div id="novi" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 120BW</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
High-quality transfer paper for Screen printing on textile with plastisol inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 120g./sm</li>
        <li>Designed for cold peel transfers</li>

</ul>
<b>Screen printing:</b><br/><br/>
Ink types: Wide range of plastisol inks can be used with or without powdered adhesives (polyester, poly
        amide, polyurethane).<br/>
<br/>
Final appearance after transferring on textile - <b>MATTE</b><br/><br/>
<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf120bw/>

</div>
<div className="okvirSlikeM"><img src={bw80} alt="bw120" style={{width: '60%'}} ></img></div>
 </div>

) : (
        <div id="novi" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 120BW</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu za plastizol boje.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 120g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Tipovi plastizola: širok spektar plastizol boja, može se koristiti sa ili bez lepkova u obliku praha ili paste (poliester,poliamid,poliuretan).<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>MAT</b><br/><br/>
<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;250 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;500 tabaka 50 x 70 cm<br /><br />
<i>*Željene dimenzije su dostupne na zahtev kupca </i></p>
<Pdf120bw/>

</div>
<div className="okvirSlikeM"><img src={bw80} alt="bw80" style={{width: '60%',objectFit:'contain'}} ></img></div>
 </div>
)}


{/* kremM */}
{language === 'en' ? (
 <div id="krem" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 125S</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
 High quality transfer paper for Screen printing on textile with water based inks.<br/><br />
<b>Properties:</b><br />
<ul>
        <li>Weight 125g./sm</li>
        <li>Designed for cold peel transfers</li>

</ul>
<b>Screen printing:</b><br/><br/>
Printing side is the side with thicker silicone coating and 
is much easier for peeling.<br/>
<br/>
Final appearance after transferring on textile - <b>GLOSSY</b><br/><br/>
<b>Default Packaging:</b><br /><br />
&nbsp;&nbsp;&nbsp;200 Sheets 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;400 Sheets 50 x 70 cm<br /><br />
<i>*other sizes available on customer request</i></p>
<Pdf125s/>

</div>
<div className="okvirSlikeM"><img src={p125S} alt="p125S" style={{width: '60%',objectFit: 'contain',padding: '10px'}} ></img></div>
 </div>
 ) : (
        <div id="krem" className="okvirM">
<div><h3 style={{textAlign: 'center'}}>TEXIMAX CP-e 125S</h3><p style={{paddingRight: '30px',paddingLeft: '30px'}}>
Visoko kvalitetan transfer papir za sito štampu na tekstilu sa bojama na vodenoj bazi.<br/><br />
<b>Svojstva:</b><br />
<ul>
        <li>težina 125g./m²</li>
        <li>dizajniran za cold peel trasfere </li>
</ul>
<b>Sito štampanje:</b><br/><br/>
Štampanje na strani sa debljim silikonskim premazom, odnosno strana koja ima manji otpor prilikom ljuštenja.<br/>
<br/>
Finalni izgled otiska nakon prenosenja na tekstil - <b>Sjajna</b><br/><br/>
<b>Podrazumevano pakovanje:</b><br /><br />
&nbsp;&nbsp;&nbsp;200 tabaka 100 x 70 cm<br/>
&nbsp;&nbsp;&nbsp;400 tabaka 50 x 70 cm<br /><br />
<i>*Željene dimenzije su dostupne na zahtev kupca </i></p>
<Pdf125s/>

</div>
<div className="okvirSlikeM"><img src={p125S} alt="p125S" style={{width: '60%',objectFit:'contain', padding: '10px'}} ></img></div>
 </div>
 )}
 </>
)}
    </>
  );
};

export default Products;

  